/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

@import "stoebich-theme.scss";

// Define the default theme (same as the example above).
$candy-app-primary: mat-palette($md-red);
$candy-app-accent:  mat-palette($md-accent, A200, A100, A400);
$candy-app-theme:   mat-light-theme((
  color: (
    primary: $candy-app-primary,
    accent: $candy-app-accent,
  )
));

// Include the default theme styles (color and default density)
@include angular-material-theme($candy-app-theme);


// Define an alternate dark theme.
$dark-primary: mat-palette($mat-blue-grey);
$dark-accent:  mat-palette($mat-amber, A200, A100, A400);
$dark-warn:    mat-palette($mat-deep-orange);
$dark-theme:   mat-dark-theme((
  color: (
    primary: $dark-primary,
    accent: $dark-accent,
    warn: $dark-warn,
  )
));

// Include the dark color styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.unicorn-dark-theme` will be affected by this alternate dark theme instead of the default theme.
.unicorn-dark-theme {
  @include angular-material-color($dark-theme);
}

html,body{
    height:100%;
    margin:0;
    padding:0;
}

body {
    min-height:100vh;
}

* {
    font-family: myriad-pro, sans-serif;    
}

.logo {
    color: #e2001a;
    font-size: 230px;
    font-weight: 700 !important;
    
    sup {
        top: 2px;
        position: relative;
    }
}

.full-width {
    width: 100%;
}

.copyright {
    color: rgba(0,0,0,.54);
    font-size: 12px;
    
}

.wrapper {
    margin: 0 24px;
}

.login-form {
    display: grid;
    max-width: 300px;
    margin: 25px auto;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right
}

.dom-center {
    margin: 25px auto;
}

.button-bar {
    padding: 25px;
    text-align: center;
}

.spacer {
    flex: 1 1 auto;
}

.headline-wrapper {
    padding-left: 24px;
}

.mat-tab-group,
.mat-tab-body-wrapper {
    height: 100%;
}

.mat-row {
    cursor: pointer;

    // &:hover {
    //     background-color: mat-color($md-accent,50);
    // }

    // &:not(.example-expanded-row):active {
    //     background-color: #efefef;
    //   }    
    
}

.mat-list a {
    text-decoration: none;
}

.mat-tab-wrapper {
    margin-top: 25px;
}

.mat-drawer-container {
    height: 100%;
}

.mat-dialog-actions {
    justify-content: center;
}

// .app-header{
//     justify-content:space-between;
//     position:fixed;
//     top:0;
//     left:0;
//     right:0;
//     z-index:2;
// }

.app-content-wrapper{    
    margin: 89px 25px 25px 25px;
    margin-top: 64px;
    z-index: 1;
}

table {
    width: 100%;
}

.data-view-table {
    margin: 15px 0;
}

app-cds-output-field {
    margin-left: 14px;
    display: block;
}


mat-card {
    margin-bottom: 5px;
}

tr.table-detail-row, 
tr.table-expanded-row {
    background: whitesmoke;
}